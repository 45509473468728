import { BaseApiService, BaseDeleteDto, BaseFindDto, BaseFindOneDto } from "@remar/shared/dist/api/baseApiService";
import { getJwt } from "@remar/shared/dist/api/jwt";
import { request, requestHeaders } from "@remar/shared/dist/api/request";
import {
	BadgeInfo,
	BuyBook,
	ICourseCompletion,
	PaymentHistory,
	User,
	UserCertificate,
	UserHelpRequest,
	UserPaymentProviderAccount,
	UserSubscription
} from "@remar/shared/dist/models";

import {
	GuestSignUpDto,
	ICancellationOptions,
	IUserActivityTrackingPayload,
	IUserCATBuyResult,
	UnlockChapterDtoResult,
	UpdateBillingAddressDto,
	UpdatePaymentMethodDto,
	UserChangePasswordDto,
	UserCreateSubscriptionDto,
	UserGetCurrentSubscriptionPaymentMethodDataDto,
	UserGetSubscriptionInfoResponseDto,
	UserInvitationDto,
	UserInvitationResponseDto,
	UserLoginDto,
	UserLoginResponseDto,
	UserResumeSubscriptionDto,
	UserSendHelpRequestDto,
	UserSignUpDto,
	UserSignUpInvitation,
	UserSignUpResponseDto,
	UserSignUpVerificationDto,
	UserSocialSignUpDto,
	UserUpdateDto,
	UserValidateDuplicateEmailResponseDto
} from "./dto";

import { UserUpdateSubscriptionDto } from "./dto/users.updateSubscription.dto";

import { GLOBAL_CONSTANTS } from "../../../constants";
import { ISocketNotifications } from "../../features/notifications/notifications.model";

import { URLS } from "../services.constants";

export class UsersService extends BaseApiService<
	User,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	UserUpdateDto,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
	token = getJwt(false);
	createSubscription(data: UserCreateSubscriptionDto): Promise<UserSubscription> {
		return request(
			`${this.baseUrl}/subscription`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<UserSubscription>;
	}

	getCancellationReasons(): Promise<ICancellationOptions> {
		return request("/cancellation-reasons", "GET") as Promise<ICancellationOptions>;
	}

	cancelUserSubscription({
		userSubscriptionId,
		cancellationReasonOptionId
	}: {
		userSubscriptionId: number;
		cancellationReasonOptionId: number;
	}): Promise<unknown> {
		return request(
			"/user-cancellation-reasons",
			"POST",
			this.emptyStringToNull({ userSubscriptionId, cancellationReasonOptionId }) as Record<string, unknown>
		) as Promise<unknown>;
	}

	cancelSubscription(id: number): Promise<unknown> {
		return request(
			`${this.baseUrl}/subscription`,
			"DELETE",
			this.emptyStringToNull({ userSubscriptionId: id }) as Record<string, unknown>
		) as Promise<unknown>;
	}

	deleteAccount(password: string): Promise<unknown> {
		return request(`${this.baseUrl}/`, "DELETE", { password }) as Promise<unknown>;
	}
	buyCATTests({
		quantity,
		paymentProviderPaymentMethodIdentifier,
		catPaymentId
	}: {
		quantity: number;
		paymentProviderPaymentMethodIdentifier: string;
		catPaymentId: number;
	}): Promise<IUserCATBuyResult> {
		return request(
			`${this.baseUrl}/payment-cat`,
			"POST",
			this.emptyStringToNull({
				paymentProviderId: 1,
				paymentProviderPaymentMethodIdentifier,
				catPaymentId,
				quantity
			}) as Record<string, unknown>
		) as Promise<{ amount?: number }>;
	}
	changeSubscriptionDate(data: UserUpdateSubscriptionDto): Promise<unknown> {
		return request(
			`${this.baseUrl}/subscription/update`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<unknown>;
	}

	forgotPassword(email: string): Promise<unknown> {
		return request(
			`${this.baseUrl}/forgot-password`,
			"POST",
			this.emptyStringToNull({ email }) as Record<string, unknown>
		) as Promise<unknown>;
	}

	forgotVerification(code: string): Promise<unknown> {
		return request(
			`${this.baseUrl}/forgot-verification`,
			"POST",
			this.emptyStringToNull({ code }) as Record<string, unknown>
		) as Promise<unknown>;
	}

	getAccountInfo(): Promise<{ user: User }> {
		return request(`${this.baseUrl}/account`, "GET") as Promise<{ user: User }>;
	}

	getCurrentSubscriptionPaymentMethodData(
		data: UserGetCurrentSubscriptionPaymentMethodDataDto
	): Promise<UserPaymentProviderAccount> {
		return request(
			`${this.baseUrl}/payment-method${this.serializeQuery(data as Record<string, unknown>, { isTopLevel: true })}`,
			"GET"
		) as Promise<UserPaymentProviderAccount>;
	}

	getSubscriptionInfo(): Promise<UserGetSubscriptionInfoResponseDto> {
		return request(`${this.baseUrl}/subscription`, "GET") as Promise<UserGetSubscriptionInfoResponseDto>;
	}

	getPaymentHistory(data: Record<string, unknown>): Promise<PaymentHistory> {
		return request(
			`${this.baseUrl}/payment-history${this.serializeQuery(data as Record<string, unknown>, { isTopLevel: true })}`,
			"GET"
		) as Promise<PaymentHistory>;
	}

	getCourseCompletion(): Promise<ICourseCompletion> {
		return request(`${this.baseUrl}/course-completion-details`, "GET") as Promise<ICourseCompletion>;
	}

	updatePaymentMethod(data: UpdatePaymentMethodDto): Promise<unknown> {
		return request(
			`${this.baseUrl}/payment-method`,
			"PATCH",
			this.emptyStringToNull(data) as Record<string, unknown>,
			{},
			false,
			true
		) as Promise<unknown>;
	}

	updateBillingAddress(data: UpdateBillingAddressDto): Promise<unknown> {
		return request(
			`${this.baseUrl}/billing-address`,
			"PATCH",
			this.emptyStringToNull(data) as Record<string, unknown>,
			{},
			false,
			true
		) as Promise<unknown>;
	}

	login(data: UserLoginDto): Promise<UserLoginResponseDto> {
		return request(`${this.baseUrl}/login`, "POST", this.emptyStringToNull(data) as Record<string, unknown>, {
			credentials: "include"
		}) as Promise<UserLoginResponseDto>;
	}

	loginByToken(data: { sessionId: string }): Promise<UserLoginResponseDto> {
		return request(`${this.baseUrl}/login-token`, "POST", this.emptyStringToNull(data) as Record<string, unknown>, {
			credentials: "include"
		}) as Promise<UserLoginResponseDto>;
	}

	logout(): Promise<void> {
		return request(`${this.baseUrl}/logout`, "POST", {}, { credentials: "include" }, false, true) as Promise<void>;
	}

	resendEmailVerification(email: string): Promise<UserValidateDuplicateEmailResponseDto> {
		return request(
			`${this.baseUrl}/resend-email-verification`,
			"POST",
			this.emptyStringToNull(email) as Record<string, unknown>
		) as Promise<UserValidateDuplicateEmailResponseDto>;
	}

	resetPassword(data: {
		code: string;
		password: string;
	}): Promise<{ status: string; sessionToken?: string; refreshToken?: string }> {
		return request(
			`${this.baseUrl}/reset-password`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<{ status: string; sessionToken?: string; refreshToken?: string }>;
	}

	changePassword(data: UserChangePasswordDto): Promise<unknown> {
		return request(
			`${this.baseUrl}/change-password`,
			"PATCH",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<unknown>;
	}

	resumeSubscription(data: UserResumeSubscriptionDto): Promise<UserSubscription> {
		return request(
			`${this.baseUrl}/subscription/resume`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<UserSubscription>;
	}

	sendHelpRequest(data: UserSendHelpRequestDto): Promise<UserHelpRequest> {
		return request(
			`${this.baseUrl}/help`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>,
			{},
			false,
			true
		) as Promise<UserHelpRequest>;
	}

	signUp(data: UserSignUpDto): Promise<UserSignUpResponseDto> {
		return request(
			`${this.baseUrl}/signup`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<UserSignUpResponseDto>;
	}

	openInstitutionSignUp(data: UserSignUpDto): Promise<UserSignUpResponseDto> {
		return request(
			`${this.baseUrl}/institution-open-signup`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<UserSignUpResponseDto>;
	}

	guestCheckout(data: GuestSignUpDto): Promise<UserSignUpResponseDto> {
		return request(
			`${this.baseUrl}/guest-checkout`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<UserSignUpResponseDto>;
	}

	guestSignUp(data: GuestSignUpDto): Promise<UserSignUpResponseDto> {
		return request(
			`${this.baseUrl}/guest-signup`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<UserSignUpResponseDto>;
	}

	guestSignUpVerification(accountClaimCode: string): Promise<UserSignUpVerificationDto> {
		return request(
			`${this.baseUrl}/guest-signup-verification`,
			"POST",
			this.emptyStringToNull({ accountClaimCode }) as Record<string, unknown>
		) as Promise<UserSignUpVerificationDto>;
	}

	validateDuplicateEmail(email: string): Promise<UserValidateDuplicateEmailResponseDto> {
		return request(
			`${this.baseUrl}/validate-duplicate-email`,
			"POST",
			this.emptyStringToNull({ email }) as Record<string, unknown>
		) as Promise<UserValidateDuplicateEmailResponseDto>;
	}

	whoami(): Promise<{ user: User }> {
		return request(`${this.baseUrl}/whoami`, "GET") as Promise<{ user: User }>;
	}

	getUserCertificate(): Promise<UserCertificate> {
		return request(`${this.baseUrl}/create-certificate`, "POST") as Promise<UserCertificate>;
	}

	facebookLogin(tokenId: string): Promise<UserLoginResponseDto> {
		return request(
			`${this.baseUrl}/login-facebook`,
			"POST",
			this.emptyStringToNull({ tokenId }) as Record<string, unknown>,
			{
				credentials: "include"
			}
		) as Promise<UserLoginResponseDto>;
	}

	facebookSignUp(data: UserSocialSignUpDto): Promise<UserSignUpResponseDto> {
		return request(
			`${this.baseUrl}/signup-facebook`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<UserSignUpResponseDto>;
	}

	googleLogin(tokenId: string): Promise<UserLoginResponseDto> {
		return request(
			`${this.baseUrl}/login-google`,
			"POST",
			this.emptyStringToNull({ tokenId }) as Record<string, unknown>,
			{
				credentials: "include"
			}
		) as Promise<UserLoginResponseDto>;
	}
	googleSignUp(data: UserSocialSignUpDto): Promise<UserSignUpResponseDto> {
		return request(
			`${this.baseUrl}/signup-google`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<UserSignUpResponseDto>;
	}
	getInvitationDetails(data: UserInvitationDto): Promise<UserInvitationResponseDto> {
		return request(
			`${this.baseUrl}/institution-invite-verification`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<UserInvitationResponseDto>;
	}

	studentSignUpInvitation(data: UserSignUpInvitation): Promise<UserSignUpResponseDto> {
		return request(
			`${this.baseUrl}/institution-invite-signup`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<UserSignUpResponseDto>;
	}

	confirmAcceptedTermsAndConditions(
		acceptedTermsAndConditions: boolean
	): Promise<{ acceptedTermsAndConditions: boolean }> {
		return request(`${this.baseUrl}/confirm-terms-and-conditions`, "PATCH", {
			acceptedTermsAndConditions
		}) as Promise<{ acceptedTermsAndConditions: boolean }>;
	}

	userActivityTracking(data: IUserActivityTrackingPayload): Promise<unknown> {
		return request(
			GLOBAL_CONSTANTS.AWS_ANALYTICS_PATH as string,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>,
			{
				headers: requestHeaders(true, {
					Authorization: `${this.token}`,
					env: GLOBAL_CONSTANTS.ENVIRONMENT,
					region: GLOBAL_CONSTANTS.AWS_API_GATEWAY_REGION
				}),
				credentials: "omit",
				keepalive: true
			}
		);
	}

	getUserBadgeInfo(): Promise<BadgeInfo> {
		return request(`${this.baseUrl}/remar-ready-badge-details`, "GET") as Promise<BadgeInfo>;
	}

	unlockChapter(chapterId: number): Promise<UnlockChapterDtoResult> {
		return request(
			`${this.baseUrl}/unlock-chapter`,
			"POST",
			this.emptyStringToNull({ chapterId }) as Record<string, unknown>
		) as Promise<UnlockChapterDtoResult>;
	}
	unlockPayedChapter({
		chapterId,
		paymentProviderId,
		paymentProviderPaymentMethodIdentifier
	}: {
		chapterId: number;
		paymentProviderId: number;
		paymentProviderPaymentMethodIdentifier: string;
	}): Promise<UnlockChapterDtoResult> {
		return request(
			`${this.baseUrl}/unlock-paid-chapter`,
			"POST",
			this.emptyStringToNull({ chapterId, paymentProviderId, paymentProviderPaymentMethodIdentifier }) as Record<
				string,
				unknown
			>
		) as Promise<UnlockChapterDtoResult>;
	}

	getUserNotifications(data: { filters: Record<string, unknown> }): Promise<ISocketNotifications> {
		return request(
			`${this.baseUrl}/notifications${this.serializeQuery(data as Record<string, unknown>, { isTopLevel: true })}`,
			"GET"
		) as Promise<ISocketNotifications>;
	}

	subscribeFirebaseNotifications(clientRegistrationToken: string): Promise<unknown> {
		return request(
			`${this.baseUrl}/push-notification-register`,
			"POST",
			this.emptyStringToNull({ clientRegistrationToken }) as Record<string, unknown>
		) as Promise<unknown>;
	}

	selectRenewSubscription(subscriptionTypeId: number): Promise<unknown> {
		return request(
			`${this.baseUrl}/select-renew-subscription`,
			"POST",
			this.emptyStringToNull({ subscriptionTypeId }) as Record<string, unknown>
		) as Promise<unknown>;
	}
	purchaseBooks(data: BuyBook): Promise<unknown> {
		return request(
			`${this.baseUrl}/buy-book`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<unknown>;
	}
}

export const usersService = new UsersService(URLS.USERS);
