import { Box, Button, alpha, makeStyles } from "@material-ui/core";

import styled from "styled-components";

import { IExtendedTheme } from "../../theme/default";

export const useStyles = makeStyles((theme: IExtendedTheme) => ({
	button: {
		display: "flex",
		gap: "8px",
		alignItems: "center",
		color: alpha(theme.palette.text.primary, 0.6)
	},
	clearButton: {
		width: "100%",
		background: "transparent",
		border: 0,
		fontFamily: "var(--poppins)",
		padding: 8,
		fontSize: theme.typography.pxToRem(12),
		fontWeight: 800,
		textAlign: "left",
		color: theme.palette.text.primary,
		paddingLeft: 30,
		cursor: "pointer"
	},
	popper: {
		border: "1px solid rgba(27,31,35,.15)",
		boxShadow: "0 3px 12px rgba(27,31,35,.15)",
		borderRadius: 3,
		width: 300,
		height: 400,
		zIndex: 1301,
		fontSize: 13,
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.colors.basic[700]
	},
	popperDisablePortal: {
		position: "relative"
	},
	paper: {
		boxShadow: "none",
		margin: 0,
		color: theme.palette.text.primary,
		fontSize: 13,
		background: theme.palette.colors.basic[700],
		borderTop: `1px solid ${theme.palette.colors.basic[600]}`
	},
	option: {
		width: "100%",
		display: "flex",
		height: 40,
		alignItems: "center",
		padding: "0 8px 0 24px"
	},
	checkboxItem: {
		width: 20,
		height: 20,
		padding: 4,
		border: "1px solid HSL(222, 13%, 19%)",
		borderRadius: 0
	},
	upgradeWrapper: {
		background: "hsl(222, 13%, 19%)",
		width: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		alignContent: "center",
		justifyContent: "center",
		gap: 16,
		padding: "16px 16px 24px"
	},
	upgradeText: {
		fontFamily: "var(--openSans)",
		fontWeight: 600,
		fontSize: "13px",
		textAlign: "center",
		opacity: 0.87
	}
}));

export const CheckboxLabel = styled.div`
	flex-grow: 1;
	display: flex;
	flex-flow: column;
	opacity: 0.83;
	font-family: var(--openSans);
	font-size: 13px;
	line-height: 1.54;
	flex-basis: 100%;
	color: #fff;
	justify-content: center;

	> span {
		font-size: 13px;
		line-height: 1.23;
		color: rgba(255, 255, 255, 0.6);
	}
`;

export const StyledDropdownButton = styled(Button)`
	& > span {
		display: flex;
		justify-content: space-between;
	}
`;

export const LoadingComponent = styled(Box)`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: ${({ theme }) => theme.palette.background.paper};
	z-index: 1;
	opacity: 0.5;
	display: flex;
	justify-content: center;
	align-items: center;
`;
