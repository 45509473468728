import React, { useEffect, useState } from "react";

import { Box, Card, IconButton, Modal, Theme, Typography, makeStyles, useTheme } from "@material-ui/core";
import { CalendarTodaySharp } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@remar/shared/dist/components/Button";
import { DatePicker } from "@remar/shared/dist/components/DatePicker";
import { SimpleModal } from "@remar/shared/dist/modals/SimpleModal";
import { formatDate } from "@remar/shared/dist/utils/myAccountUtils";
import { addMonths } from "date-fns";
import { useAppDispatch, useAppSelector } from "store";
import {
	changeSubscriptionDate,
	selectDisplaySubscriptionModal,
	selectSubscriptionInfo,
	selectUpdateSubcriptionIsLoading,
	setChangeDisplaySubscriptionDateModal
} from "store/features/MyAccount/myAccountSlice";

const useStyles = makeStyles({
	title: {
		fontWeight: "bold",
		fontSize: 22,
		marginBottom: 24,
		marginLeft: 45
	},
	root: {},
	card: {
		width: 450
	},
	cancelBtn: {
		backgroundColor: "transparent",
		color: "rgba(255, 255, 255, 0.6)",
		border: "1px solid #6b6e75",
		marginRight: "10px",
		fontWeight: "bold"
	}
});

type ChangeSubscriptionProps = {
	closeSideEffect?: (date?: Date) => void;
	onCloseEnd?: () => void;
};
const ChangeSubscription = ({ closeSideEffect, onCloseEnd }: ChangeSubscriptionProps) => {
	const theme = useTheme<Theme>();
	const dispatch = useAppDispatch();
	const classes = useStyles();
	const isLoading = useAppSelector(selectUpdateSubcriptionIsLoading);

	const subscriptionInfo = useAppSelector(selectSubscriptionInfo);
	const open = useAppSelector(selectDisplaySubscriptionModal);

	const [date, setDate] = useState();
	const [modal, setModal] = useState(false);

	useEffect(() => {
		if (subscriptionInfo) {
			setDate(subscriptionInfo.subscription.data.subscriptionStartDate);
		}
	}, [subscriptionInfo]);

	const onSubmit = () => {
		const data = { subscriptionId: subscriptionInfo!.subscription.id, startDate: date };
		dispatch(
			changeSubscriptionDate({
				data,
				sideEffect: () => {
					onClose();
					setModal(false);
					closeSideEffect && closeSideEffect(date);
				}
			})
		);
	};

	const onClose = () => {
		dispatch(setChangeDisplaySubscriptionDateModal(false));
		onCloseEnd && onCloseEnd();
	};

	if (!date) return null;

	return (
		<>
			<Modal open={open} onClose={onClose}>
				<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
					<Card className={classes.card}>
						<Box display="flex" justifyContent="flex-end" mb={3}>
							<IconButton onClick={onClose}>
								<CloseIcon color="disabled" />
							</IconButton>
						</Box>
						<Box className={classes.root}>
							<Typography className={classes.title} variant="h4">
								Change The Start Date
							</Typography>
							<DatePicker
								autoOk
								open={false}
								disablePast
								disableToolbar
								orientation="portrait"
								variant="static"
								openTo="date"
								maxDate={addMonths(new Date(subscriptionInfo?.subscription?.data?.subscriptionStartDate), 3)}
								value={date}
								onChange={setDate}
							/>
						</Box>
						<Box display="flex" justifyContent="center" alignItems="center" mt={3} gridGap={10}>
							<Button variant="filled" color="secondary" onClick={() => setModal(true)}>
								{`Start on ${formatDate(date)}`}
							</Button>
							<Button
								color="primary"
								variant="filled"
								onClick={() => {
									setDate(new Date());
									setModal(true);
								}}
							>
								Start Today
							</Button>
						</Box>
					</Card>
				</Box>
			</Modal>
			<SimpleModal
				theme={theme}
				title=""
				icon={<CalendarTodaySharp style={{ width: "50px", height: "50px", fill: "#586179" }} />}
				open={modal}
				onClose={() => setModal(false)}
				text={`Are you sure you want to start the course on ${formatDate(date)}? 
				If you choose to do so, the course will begin immediately.`}
				footer={
					<Box display="flex" gridGap={10}>
						<Button variant="filled" color="secondary" disabled={isLoading} onClick={() => setModal(false)}>
							Cancel
						</Button>
						<Button color="primary" variant="filled" onClick={onSubmit} loading={isLoading}>
							{`Start on ${formatDate(date)}`}
						</Button>
					</Box>
				}
			/>
		</>
	);
};

export default ChangeSubscription;
