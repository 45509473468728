import React, { useMemo } from "react";

import { Box, Typography } from "@material-ui/core";
import { Country, StripeIntegrationData, UserSubscriptionDataModel } from "@remar/shared/dist/models";

import { Integrations } from "@remar/shared/dist/models/package.model";
import { formatUSD } from "@remar/shared/dist/utils/formatUSD";
import { add, format } from "date-fns";

import { useAppSelector } from "store";
import { getFullState as getAuthFullState } from "store/features/Auth/authSlice";
import { getFullState } from "store/features/Theme/theme.slice";
import { IPaymentConfiguration, InvitedBy } from "store/services";

import logo from "assets/images/logo.svg";

import {
	BoldText,
	BookLabel,
	BooksWrapper,
	CourseNameWrapper,
	DescriptionText,
	Disclaimer,
	SubTotalWrapper,
	TotalWrapper,
	useStyles
} from "../components/styles";
const PAID = "Paid";
const titleStyle = { fontSize: "22px", fontWeight: 700 };
const Summary = ({
	invitedBy,
	paymentConfiguration,
	subscriptionData,
	country,
	shippingCovered
}: {
	invitedBy: InvitedBy | undefined;
	paymentConfiguration: IPaymentConfiguration | undefined;
	subscriptionData: UserSubscriptionDataModel | undefined;
	country: Country;
	shippingCovered: boolean;
}) => {
	const { logoImageUrl, isLoading } = useAppSelector(getFullState);
	const { selectedShippingPlan, multipleShippingPlan } = useAppSelector(getAuthFullState);
	const { days = 0, months = 0 } = subscriptionData?.duration ?? {};
	const renewalDate = format(add(new Date(), { days, months }), "dd, MMM yyyy");

	const freeShipping = useMemo(
		() =>
			subscriptionData?.allowedLocationPackages[0].course?.freeShipping ||
			country?.freeShippingForInstitution ||
			paymentConfiguration?.coverShipping,
		[country, subscriptionData?.allowedLocationPackages, paymentConfiguration]
	);

	const subTypeEIDItems = useMemo(
		() =>
			subscriptionData?.subTypeEIDItems?.find(
				item => item.integrationId === Integrations.StripeIntegrationId && item.parentId === null
			),
		[subscriptionData?.subTypeEIDItems]
	);
	const isCourseFeesCoveredByInstAdmin = useMemo(() => !!paymentConfiguration?.coverInitial, [paymentConfiguration]);

	const coursePrice = useMemo(() => (subTypeEIDItems?.data as StripeIntegrationData).price || 0, [subTypeEIDItems]);
	const displayedCoursePrice = useMemo(
		() => (isCourseFeesCoveredByInstAdmin ? 0 : coursePrice),
		[coursePrice, isCourseFeesCoveredByInstAdmin]
	);

	const books = useMemo(() => subTypeEIDItems?.children, [subTypeEIDItems]);

	const shippingPlan = useMemo(
		() => (books?.length > 1 ? multipleShippingPlan : selectedShippingPlan),
		[books, multipleShippingPlan, selectedShippingPlan]
	);
	const havePhysicalBook = useMemo(
		() => books?.some(book => book.integrationId !== Integrations.DigitalAssetIntegrationId),
		[books]
	);

	const canApplyShippingDiscount = useMemo(
		() => country && shippingCovered && freeShipping && havePhysicalBook,
		[country, freeShipping, havePhysicalBook, shippingCovered]
	);
	const shippingPrice = useMemo(() => shippingPlan?.data["price"] || 0, [shippingPlan?.data]);

	const total = useMemo(() => {
		return (canApplyShippingDiscount ? 0 : shippingPrice) + displayedCoursePrice;
	}, [canApplyShippingDiscount, shippingPrice, displayedCoursePrice]);

	const classes = useStyles();
	return (
		<Box className={`${classes.summaryRoot} ${classes.customFlex}`}>
			<Box>
				<Box mb={4}>
					<Typography style={titleStyle}>Invited by</Typography>
					<Box display="flex" className={classes.invitedBy} p={1} mt={2}>
						<Box borderRadius={5} overflow="hidden" width={40} height={40} mr={1}>
							{isLoading && <img alt="institution logo" className={classes.bookImg} src={logoImageUrl || logo} />}
						</Box>
						<Box display="flex" flexDirection={"column"}>
							<Typography
								style={{ fontSize: "15px", fontWeight: 700 }}
							>{`${invitedBy?.firstName} ${invitedBy?.lastName}`}</Typography>
							<Typography style={{ fontSize: "12px" }}>{invitedBy?.locationName}</Typography>
						</Box>
					</Box>
				</Box>

				<Box mb={2}>
					<Typography style={titleStyle}>Order Summary</Typography>
				</Box>
				<CourseNameWrapper $spaceBetween p={1} mt={2}>
					<Box display="flex" flexDirection={"column"}>
						<DescriptionText>Course Name</DescriptionText>
						<BoldText>{subscriptionData?.allowedCourses?.[0]?.name}</BoldText>
					</Box>
					<Box>
						<BoldText>{displayedCoursePrice ? formatUSD(displayedCoursePrice) : PAID}</BoldText>
					</Box>
				</CourseNameWrapper>
				<BooksWrapper>
					{books?.map((item, i) => (
						<CourseNameWrapper key={i} p={1} mt={2}>
							{item.mainImageUrl && (
								<Box mr={2}>
									<img width="50px" src={item.mainImageUrl} alt="book name" />
								</Box>
							)}
							<Box display="flex" flexDirection={"column"} alignItems={"flex-start"}>
								<BoldText>{item.data?.name}</BoldText>
								<BookLabel key={i} $isPhysical={item.integrationId !== Integrations.DigitalAssetIntegrationId}>
									{item.integrationId !== Integrations.DigitalAssetIntegrationId ? "Physical Book" : "Digital book"}
								</BookLabel>
							</Box>
						</CourseNameWrapper>
					))}
				</BooksWrapper>
				<SubTotalWrapper mt={3}>
					<Box className={"subTotal"} mb={0.5}>
						<p>Subtotal</p>
						<p>{displayedCoursePrice ? formatUSD(displayedCoursePrice) : PAID}</p>
					</Box>

					{shippingPlan && (
						<Box display="flex" justifyContent="space-between">
							<p>{shippingPlan?.name}</p>
							<p>+ {formatUSD(shippingPrice as number)}</p>
						</Box>
					)}

					{canApplyShippingDiscount && (
						<Box display="flex" justifyContent="space-between">
							<p>Discount in International shipping fee</p>
							<p>- {formatUSD(shippingPrice as number)}</p>
						</Box>
					)}
				</SubTotalWrapper>
				<TotalWrapper mt={3}>
					<p>Your Total</p>
					<p>{total ? formatUSD(total) : PAID}</p>
				</TotalWrapper>
				{isCourseFeesCoveredByInstAdmin && (
					<Disclaimer mt={2}>
						<p>Course fee is covered by institution until renewal. Renewal of course starts on {renewalDate}.</p>
					</Disclaimer>
				)}
			</Box>
		</Box>
	);
};

export default Summary;
