import { BaseModel } from "./base.model";
import { LocationPackageTypeModel } from "./locationPackageType.model";
import { Package } from "./packages.model";
import { SubscriptionDuration } from "./userSubscriptionType.model";
import { StripeIntegrationData } from "./userSubscriptionTypeExternalIntegrationDataItems.model";

export interface LocationPackage extends Omit<Package, "isDefault" | "allowedUST" | "locations"> {
	allowedUST: UserSubscrptnType;
	isDeleted: boolean;
	locationPackageType: LocationPackageTypeModel;
}

export interface UserSubscrptnType extends BaseModel {
	description: string;
	duration: SubscriptionDuration;
	isActive: true;
	isRecurring: boolean;
	isTrial: boolean;
	name: string;
	nextSubscriptionTypeId: number;
	subTypeEIDItems: Array<SubTypeEIDItem>;
	userSubscriptionTypeCategoryId: number;
}

interface SubTypeEIDItem extends BaseModel {
	data: StripeIntegrationData;
	integrationId: number;
	isForBook: boolean;
	mainImageKey: string | null;
	mainImageUrl: string;
	parentId: string | null;
	subscriptionTypeId: number;
}

export enum Integrations {
	StripeIntegrationId = 1, // eslint-disable-line no-unused-vars
	AcutrackIntegrationId = 2, // eslint-disable-line no-unused-vars
	DigitalAssetIntegrationId = 3, // eslint-disable-line no-unused-vars
	PrintBindShipIntegrationId = 4 // eslint-disable-line no-unused-vars
}
