import React, { useEffect, useMemo } from "react";

import { Badge, ClickAwayListener, IconButton, List, ListItem, Tooltip, useTheme } from "@material-ui/core";
import { Notifications as NotificationsIcon } from "@material-ui/icons";

import { NotificationDeliveryMethodEnum } from "@remar/shared/dist/models";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";

import { useAppDispatch, useAppSelector } from "store";
import { getFullState, getUserNotifications } from "store/features/notifications/notifications.slice";

const BellNotifications = () => {
	const theme = useTheme<IExtendedTheme>();
	const dispatch = useAppDispatch();
	const { bellNotifications: bellNotificationsState } = useAppSelector(getFullState);
	const [expanded, setExpanded] = React.useState(false);

	useEffect(() => {
		dispatch(
			getUserNotifications({
				filters: {
					"notification.deliveryMethod": NotificationDeliveryMethodEnum.Push
				}
			})
		);
	}, [dispatch]);

	const bellNotifications = useMemo(() => bellNotificationsState?.items || [], [bellNotificationsState]);

	return (
		<Tooltip title="Notifications">
			<IconButton size="small" onClick={() => setExpanded(s => !s)}>
				<Badge badgeContent={bellNotifications.length} color="primary">
					<NotificationsIcon style={{ width: "20px", height: "20px", fill: "hsl(224,16%,41%)" }} />
				</Badge>
				{expanded && (
					<ClickAwayListener onClickAway={() => setExpanded(false)}>
						<List
							style={{
								position: "absolute",
								width: "400px",
								top: "55px",
								borderRadius: "4px",
								padding: "16px",
								background: theme.palette.background.paper,
								boxShadow: "0 0 12px rgba(27,31,35,.15)"
							}}
						>
							{bellNotifications.length === 0 && (
								<ListItem style={{ justifyContent: "center" }}>No notifications yet</ListItem>
							)}
							{bellNotifications.map(item => (
								<ListItem key={item.id}>{item.notification.body}</ListItem>
							))}
						</List>
					</ClickAwayListener>
				)}
			</IconButton>
		</Tooltip>
	);
};

export default BellNotifications;
