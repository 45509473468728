import { differenceInCalendarDays, format } from "date-fns";

import { UserBadgeTypeEnum } from "../models";

export const getRemainingDays = (givenDate: string | Date): number =>
	differenceInCalendarDays(typeof givenDate === "string" ? new Date(givenDate) : givenDate, new Date());

export const formatDate = (givenDate: string | Date): string => format(new Date(givenDate), "MMM dd, yyyy");
export const formatTime = (givenDate: string | Date): string => format(new Date(givenDate), "hh:mm aa");

export const formatAdminDate = (givenDate: string | Date): string => format(new Date(givenDate), "MM.dd.yyyy");

export const getBadgeTooltip = (type: UserBadgeTypeEnum, createdAt?: string): string => {
	switch (type) {
		case UserBadgeTypeEnum.ReMarReadyBadge:
			return (
				"This student has 100% course completion, Passing score on CAT Exam, \n" +
				"and at least 55 hours of Time Spent Training in the V2\n" +
				"\n" +
				`Received on ${format(Date.parse(createdAt as string), "MM/dd/yyyy")}`
			);
		case UserBadgeTypeEnum.InactivityBadge:
			return (
				"This student has less than 20% course completion after 10 days\n" +
				"or spent less than 5 hours training after 7 days"
			);
	}
};
