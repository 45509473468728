import React, { useCallback, useMemo, useRef } from "react";

import {
	Box,
	Checkbox,
	IconButton,
	InputAdornment,
	Paper,
	Popper,
	TextField,
	Typography,
	useTheme
} from "@material-ui/core";

import { Add, Done, Search as SearchIcon } from "@material-ui/icons";
import ChevronDown from "@material-ui/icons/KeyboardArrowDown";
import ChevronUp from "@material-ui/icons/KeyboardArrowUp";
import { Autocomplete, AutocompleteCloseReason } from "@material-ui/lab";

import { debounce } from "lodash";

import { CheckboxLabel, StyledDropdownButton, useStyles } from "./styles";

import StudentHatSvg from "../../assets/icons/icon-student-hat.svg";

import { IExtendedTheme } from "../../theme/default";
import SharedButton from "../Button";

const iconCheckBox = <Add fontSize="small" htmlColor="#FFFFFF" />;
const iconCheckBoxChecked = <Done fontSize="small" htmlColor="#30D14D" />;

type AutocompleteFilterProps<T> = {
	options: T[];
	value: T[];
	setValue: (v: T[]) => void;
	filterName: string;
	filterIcon?: React.ReactNode;
	noOptionsText?: string;
	loadMoreResults?: () => void;
	onTextInputChange?: (text: string) => void;
	isTrial?: boolean;
	onUpgradeClick?: () => void;
};
const AutocompleteFilter = <T extends { id: number; name: string }>({
	options,
	value,
	setValue,
	filterName,
	filterIcon,
	noOptionsText,
	loadMoreResults,
	onTextInputChange,
	isTrial = false,
	onUpgradeClick = () => {}
}: AutocompleteFilterProps<T>) => {
	const classes = useStyles();
	const theme = useTheme<IExtendedTheme>();
	const scrollRef = useRef<HTMLElement>();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [textInput, setTextInput] = React.useState("");
	const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
	const id = useMemo(() => (open ? "github-label" : undefined), [open]);
	const showClearButton = useMemo(() => Boolean(value?.length), [value]);
	const title = useMemo(() => `${filterName} (${value?.length ? value.length : "All"})`, [filterName, value.length]);

	const handleClick = e => {
		setAnchorEl(s => (!s ? e.currentTarget : null));
	};

	const handleClose = (_, reason: AutocompleteCloseReason) => {
		if (_.relatedTarget && _.relatedTarget.id === "clearAll") {
			setValue([]);
		}
		if (_.relatedTarget && _.relatedTarget.id === "upgrade") {
			onUpgradeClick();
		}
		if (reason === "toggleInput") return;
		if (anchorEl) {
			anchorEl.focus();
		}
		setAnchorEl(null);
	};

	const debouncedSearch = useCallback(
		debounce((searchText: string) => {
			onTextInputChange && onTextInputChange(searchText);
		}, 500),
		[]
	);

	// memoizing  is important to maintain scrolling position when loading more items or selecting an item
	const customPaper = useCallback(
		({ children, ...rest }) => (
			<Paper elevation={8} {...rest}>
				{showClearButton && (
					<button className={classes.clearButton} id={"clearAll"}>
						Clear All Selections
					</button>
				)}
				{children}
				{isTrial && (
					<Box className={classes.upgradeWrapper}>
						<img src={StudentHatSvg} alt="student_hat" />
						<Typography className={classes.upgradeText}>Upgrade your subscription to use filters and more!</Typography>
						<SharedButton fullWidth size="large" variant="filled" color="primary" id={"upgrade"}>
							Upgrade Now
						</SharedButton>
					</Box>
				)}
			</Paper>
		),
		[]
	);

	return (
		<>
			<StyledDropdownButton
				startIcon={filterIcon}
				endIcon={open ? <ChevronDown /> : <ChevronUp />}
				className={classes.button}
				aria-describedby={id}
				onClick={handleClick}
			>
				{title}
			</StyledDropdownButton>
			<Popper id={id} className={classes.popper} open={open} anchorEl={anchorEl} placement="bottom-end">
				<Autocomplete
					open={open}
					classes={{ paper: classes.paper, popperDisablePortal: classes.popperDisablePortal }}
					multiple
					disablePortal
					closeIcon={null}
					clearText="Clear All Selections"
					loadingText="Loading..."
					onClose={handleClose}
					renderTags={() => null}
					disableCloseOnSelect
					style={{ width: 300 }}
					value={value}
					onChange={(_, v) => setValue(v)}
					onInputChange={(_, newInputValue, reason) => {
						if (loadMoreResults && reason !== "reset") {
							debouncedSearch(newInputValue);
						}
					}}
					inputValue={textInput}
					options={options || []}
					getOptionLabel={o => o.name}
					getOptionSelected={(o, v) => o.id === v.id}
					placeholder="All"
					filterOptions={loadMoreResults ? x => x : undefined} // disables default filtering if options are async
					PaperComponent={customPaper}
					noOptionsText={noOptionsText}
					renderOption={(o, { selected }) => (
						<Box key={o.name} className={classes.option}>
							<CheckboxLabel>{o.name}</CheckboxLabel>
							<Checkbox
								icon={iconCheckBox}
								checkedIcon={iconCheckBoxChecked}
								color="primary"
								size="small"
								className={classes.checkboxItem}
								checked={selected}
								value={selected}
							/>
						</Box>
					)}
					renderInput={p => (
						<TextField
							ref={p.InputProps.ref}
							inputProps={p.inputProps}
							fullWidth
							hiddenLabel
							autoFocus
							onChange={e => setTextInput(e.target.value)}
							InputProps={{
								...p.InputProps,
								style: { padding: "4px 8px", color: theme.palette.text.primary, background: "transparent" },
								disableUnderline: true,
								placeholder: "Search",
								startAdornment: (
									<InputAdornment position="start">
										<IconButton size="small">
											<SearchIcon style={{ color: "hsl(223,10%,58%)" }} height={20} width={20} />
										</IconButton>
									</InputAdornment>
								)
							}}
							color="primary"
							variant="filled"
						/>
					)}
					ListboxProps={{
						ref: scrollRef,
						onScroll: event => {
							const listBoxNode = event.currentTarget;
							const scrollPosition = Math.ceil(listBoxNode.scrollTop + listBoxNode.clientHeight);
							if (scrollPosition === listBoxNode.scrollHeight && loadMoreResults) {
								loadMoreResults();
							}
						}
					}}
				/>
			</Popper>
		</>
	);
};

export default AutocompleteFilter;
