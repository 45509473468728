import React, { CSSProperties, useState } from "react";

import {
	Box,
	Divider,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

import { v4 as uuid } from "uuid";

import ColumnFilterWrapper from "./ColumnFilterWrapper";

import { IRowProps, ITableProps, alignments, sortDirectionEnum } from "./columnFilters/types";

import {
	ClosedChevron,
	SortArrowWrapper,
	StyledCell,
	StyledSubCell,
	StyledTableRow,
	StyledTableSubRow,
	useStyles
} from "./styles";

import ChevronDownSvg from "../../assets/icons/icon-chevron-down.svg";
import FileCheck from "../../assets/icons/icon-file-check.svg";
import FolderOpen from "../../assets/icons/icon-folder-open.svg";
import FolderClosed from "../../assets/icons/icon-folderSVG.svg";

const Row = ({
	item,
	childItems,
	columns,
	index,
	customCellStyle,
	disabled,
	hasNestedRows = false,
	onRowClick
}: IRowProps) => {
	const { hasChildren } = item;
	const [isExpanded, setIsExpanded] = useState(!!item.isExpanded);

	return (
		<>
			<StyledTableRow onClick={() => onRowClick && onRowClick(item)}>
				{columns.map(({ Cell, width, alignment }, columnIndex) => {
					const canShowIcons = hasNestedRows && columnIndex === 0;
					return (
						<StyledCell
							width={width}
							key={uuid()}
							align={alignment}
							$disabled={disabled}
							style={{ ...customCellStyle }}
						>
							<Box display={"flex"} alignItems={"center"}>
								{canShowIcons && hasChildren && (
									<IconButton
										style={{ width: "40px", height: "40px", marginRight: "5px" }}
										onClick={() => hasChildren && setIsExpanded(prevState => !prevState)}
									>
										<ClosedChevron src={ChevronDownSvg} alt="chevron" isExpanded={isExpanded} />
									</IconButton>
								)}
								{canShowIcons &&
									(hasChildren ? (
										<img
											src={isExpanded ? FolderOpen : FolderClosed}
											alt={isExpanded ? "Folder open" : "Folder close"}
											width={20}
											height={20}
											style={{ marginRight: "10px" }}
										/>
									) : (
										<img
											src={FileCheck}
											alt="File check"
											width={20}
											height={20}
											style={{ marginRight: "10px", marginLeft: "45px" }}
										/>
									))}
								{Cell({ rowData: item, rowIndex: index })}
							</Box>
						</StyledCell>
					);
				})}
			</StyledTableRow>
			{hasChildren &&
				childItems.map(child => (
					<StyledTableSubRow key={uuid()} $isExpanded={isExpanded}>
						{columns.map(({ ChildCell, width, alignment }) => (
							<StyledSubCell
								key={uuid()}
								width={width}
								align={alignment}
								$disabled={disabled}
								style={{ ...customCellStyle }}
							>
								{ChildCell && ChildCell({ parentRowData: item, rowData: child, rowIndex: index })}
							</StyledSubCell>
						))}
					</StyledTableSubRow>
				))}
		</>
	);
};

/*
to use nested rows in tables

<MaterialTable data={[
  {…data,
    hasChildren:true, // `true` if a row has children
    childItems:{[…children]} // children data array
    isExpanded: false // `true` if a parent row should be expanded
  }
  ]}
  hasNestedRows={true} // show/hide chevron & folder icons for parent rows
  />
*/

const mobileMenuStyle: CSSProperties = {
	position: "absolute",
	right: "10px",
	top: "0",
	bottom: "0",
	margin: "auto 0",
	height: "50px"
};
export const MaterialTable = <T extends Record<string, unknown>>({
	columns,
	data,
	customStyle,
	height,
	hideHeader = false,
	customTableStyle,
	rowCustomCellStyle,
	customCellStyle,
	disableRow,
	hasNestedRows,
	showMobileView,
	onRowClick
}: ITableProps<T>) => {
	const classes = useStyles();
	return (
		<TableContainer className={classes.tableContainer} component={Paper} style={{ height, ...customStyle }}>
			{showMobileView ? (
				<Box display={"flex"} flexDirection={"column"}>
					{data.map(item => (
						<Box key={uuid()} m={1} style={{ position: "relative" }} onClick={() => onRowClick && onRowClick(item)}>
							{columns.map(({ Cell, dataKey }, index) => (
								<Box
									key={uuid()}
									mb={1}
									style={dataKey === "menu" ? mobileMenuStyle : {}}
									onClick={e => dataKey === "menu" && e.stopPropagation()}
								>
									{Cell && Cell({ rowData: item, rowIndex: index })}
								</Box>
							))}
							<Divider />
						</Box>
					))}
				</Box>
			) : (
				<Table className={classes.table} style={{ ...customTableStyle }} aria-label="simple table">
					{!hideHeader && (
						<TableHead className={classes.theader}>
							<TableRow>
								{columns.map(({ label, dataKey, alignment, filter, sort, sortDirection }) => {
									return (
										<TableCell
											key={dataKey}
											align={alignment as alignments}
											style={{ ...customCellStyle, padding: "16px", cursor: sort ? "pointer" : "unset" }}
											onClick={() => {
												if (sort) {
													const reverseDirection =
														sortDirection === sortDirectionEnum.ASC ? sortDirectionEnum.DESC : sortDirectionEnum.ASC;
													// sets direction to null on the third click to reset column sort
													const updatedDirection = sortDirection === sortDirectionEnum.DESC ? null : reverseDirection;
													// if sortDirection is null then we set initial direction to ASC order
													sort(sortDirection ? updatedDirection : sortDirectionEnum.ASC);
												}
											}}
										>
											<Box display={"flex"} justifyContent={alignment}>
												{label}
												{filter && <ColumnFilterWrapper filter={filter} />}
												{sort && (
													<SortArrowWrapper sortDirection={sortDirection}>
														<ArrowDropUp /> <ArrowDropDown />
													</SortArrowWrapper>
												)}
											</Box>
										</TableCell>
									);
								})}
							</TableRow>
						</TableHead>
					)}

					<TableBody>
						{data.map((item, index) => (
							<Row
								key={uuid()}
								index={index}
								item={item}
								childItems={item.childItems || []}
								disabled={disableRow && disableRow(item)}
								columns={columns}
								customCellStyle={rowCustomCellStyle}
								hasNestedRows={hasNestedRows}
								onRowClick={() => onRowClick && onRowClick(item)}
							/>
						))}
					</TableBody>
				</Table>
			)}
		</TableContainer>
	);
};

export default MaterialTable;
